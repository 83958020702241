.CreatorsPartners {
  scroll-margin-top: 100px;
  .logos {
    margin-top: 75px;
    width: 100%;
    max-width: 1100px;
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0;
    row-gap: 50px;
    justify-items: center;
    align-items: center;
    @media (orientation: portrait) {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 15px;
      row-gap: 25px;
    }
    opacity: 1;
    .logo {
      max-width: 100%;
      max-height: 44px;
      &.linkinpark {
        max-height: 60px;
      }
      &.hsv {
        max-height: 60px;
        opacity: 0;
      }
      &.timewarp {
        max-height: 20px;
      }
      &.techstars {
        max-height: 60px;
      }
      &.sxsw {
        max-height: 88px;
      }
      &.quake {
        max-height: 40px;
      }
      &.hubraum {
        max-height: 36px;
      }
      @media (max-width: 500px) {
        max-height: 30px;
        &.linkinpark {
          max-height: 45px;
        }
        &.hsv {
          max-height: 45px;
        }
        &.techstars {
          max-height: 40px;
        }
        &.sxsw {
          max-height: 60px;
        }
        &.quake {
          max-height: 24px;
        }
      }
    }
  }
}
