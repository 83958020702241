.CreatorsHero {
  position: relative;
  background: linear-gradient(
    220deg,
    rgba(248, 35, 246, 1) 0%,
    rgba(104, 139, 219, 1) 41%,
    rgba(182, 99, 198, 1) 74%,
    rgba(247, 9, 9, 1) 100%
  );
  height: 80vh;
  color: white;
  font-weight: 400;
  @media (orientation: portrait) {
    height: 50vh;
  }
  .masked {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .content {
    position: absolute;
    padding: 1vh 7vw;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .marble-pink {
    position: absolute;
    bottom: -25vw;
    left: -3vw;
    width: 63vw;
    opacity: 1;
    @media (orientation: portrait) {
      width: 75vw;
      left: -8vw;
      bottom: -30vw;
    }
  }
  .claim {
    flex-grow: 2;
    font-size: 5vh;
    width: 50vw;
    max-width: 800px;
    display: flex;
    padding-top: 3vh;
    font-weight: 600;
    @media (orientation: portrait) {
      padding-top: 1vh;
      font-size: 4vh;
    }
    @media (max-width: 600px) {
      font-size: 6vw;
      width: 55vw;
    }
    @media (max-height: 600px) {
      font-size: 5vw;
    }
  }
  .mask {
    position: absolute;
    bottom: 0;
    left: -2px;
    width: calc(100vw + 2px);
  }
  .phone {
    position: absolute;
    right: 5vw;
    bottom: -3vh;
    height: 60vh;
    @media (orientation: portrait) {
      right: 2vw;
      height: 44vh;
    }
    @media (max-width: 500px) {
      height: auto;
      width: 35vw;
      @media (max-height: 700px) {
        width: 30vw;
      }
    }
  }
}
