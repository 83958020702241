.MarbleverseAutoMarbles {
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 40px;
  justify-content: center;
  .caption {
    font-size: 36px;
    font-weight: 400;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    padding-right: 40px;
    text-align: center;
  }
  .visual {
    position: relative;
    bottom: -15vh;
    display: flex;
    align-items: flex-start;
    .background {
      width: calc(100% - 5vw);
      height: calc(100% - 4vw);
      position: absolute;
      margin: 2vw 2.5vw;
      background: #100230;
      z-index: 0;
      overflow: hidden;
      .info-marble {
        position: absolute;
        top: -3%;
        left: 50%;
        transform: translateX(-50%);
        width: 150%;
      }
      .marble-caption {
        font-size: 32px;
        color: white;
        z-index: 2;
        position: absolute;
        top: 60%;
        text-align: center;
        width: 100%;
        font-weight: 400;
      }
    }
    .phone {
      height: 90vh;
      z-index: 1;
    }
  }
}
