$bubbles_total: 30;
$bubbles_scale: 0.4;
$anim_time: 6;

//*::-webkit-media-controls-panel {
//  display: none !important;
//  -webkit-appearance: none;
//}
//*::-webkit-media-controls-play-button {
//  display: none !important;
//  -webkit-appearance: none;
//}
//*::-webkit-media-controls-start-playback-button {
//  display: none !important;
//  -webkit-appearance: none;
//}

.MarbleverseBubbles {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-size: cover;
  background-color: #0a0125;
  background-repeat: no-repeat;
  background-position: center;
  .video-wrapper,
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .bubble {
    $light-purple: #510c79;
    background: radial-gradient(circle at top, transparent 60%, $light-purple);
    .ios & {
      background: radial-gradient(circle at top, transparent 30%, lighten($light-purple, 30%));
    }
    filter: blur(2px);
    display: inline-block;
    width: 40px;
    height: 40px;
    // border: 1px solid #ccc;
    border-radius: 50%;
    position: absolute;
    box-sizing: border-box;
    @for $bubble from 1 through $bubbles_total {
      &-#{$bubble} {
        opacity: (random(100)) * 0.01;
        left: #{random(100)}vw;
        top: #{random(100)}vh;
        // background-color: rgb(random(255), random(255), random(255));
        animation: bubble-move-#{$bubble} #{(30 + $bubble * 0.02) * $anim_time}s infinite;
        transform: translate(0, 0) scale((random(50) + 5) * $bubbles_scale);
      }
    }
  }
}

@for $bubble from 1 through $bubbles_total {
  @keyframes bubble-move-#{$bubble} {
    #{random(30) + 30}% {
      transform: translate(#{random(100) - 100}vw, #{random(100) - 100}vh);
    }
  }
}
