$phone: 700px;

.NftSignUp {
  padding-top: 10vh;
  background: white;
  height: 100vh;
  transform: translateY(100vh);
  .visual {
    position: relative;
    height: 90vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    color: white;
    font-weight: 600;
    text-align: center;
    .marbles {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      opacity: 0;
    }
    .signup {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100vw;
      transform: translate3D(0, 0, 10px);
      font-size: 48px;
      text-shadow: $gray-700 0 0 3px;
      @media (max-width: $phone) {
        font-size: 30px;
      }
      .call-to-action {
        padding: 0 30px;
      }
      form {
        margin-top: 30px;
        display: flex;
        align-items: center;
        @media (max-width: $phone) {
          margin-top: 20px;
          flex-direction: column;
        }
      }
      input {
        font-size: 22px;
        padding: 8px 25px;
        border-radius: 30px;
        width: 400px;
        max-width: calc(100vw - 200px);
        @media (max-width: $phone) {
          font-size: 20px;
          max-width: 80vw;
        }
      }
      .button {
        font-size: 22px;
        margin-left: 20px;
        border: 2px solid white;
        border-radius: 30px;
        padding: 10px 20px;
        white-space: nowrap;
        line-height: 1;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 175px;
        background: white;
        color: black;
        text-shadow: none;
        transition: all ease-in 0.5s;
        &.disabled {
          background: transparent;
          color: white;
          cursor: not-allowed;
        }
        &.wait {
          cursor: wait;
        }
        @media (max-width: $phone) {
          margin-left: 0;
          margin-top: 30px;
          font-size: 17px;
          padding: 11px 20px;
          min-width: 145px;
        }
      }
      .feedback {
        margin-top: 30px;
        font-size: 20px;
        height: 20px;
      }
    }
  }
}
