.NftLandingPage {
  .fake-scroll {
    height: calc(600vh + 300vw + 5500px);
  }
  > h1,
  > div:not(.fake-scroll) {
    position: fixed;
    width: 100vw;
  }
}
