.Creators {
  .page-content {
    display: flex;
    flex-direction: column;
    width: 80vw;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10vh;
    padding-bottom: 10vh;
    @media (orientation: portrait) {
      width: 90vw;
    }
  }
  .CreatorsZero {
    margin-top: 10vh;
  }
  .benefits {
    margin-top: 5vh;
    .CreatorsBenefit {
      margin: 7vh 0;
    }
  }
  .CreatorsFeatures {
    margin-top: 75px;
    @media (max-width: 750px) {
      margin-top: 50px;
    }
  }
  .CreatorsTiers {
    margin-top: 300px;
    @media (max-width: 750px) {
      margin-top: 100px;
    }
  }
  .CreatorsPartners {
    margin-top: 150px;
    @media (max-width: 750px) {
      margin-top: 100px;
    }
  }
  .section-divider {
    margin-bottom: 50px;
    text-align: center;
    .name {
      color: #ff00ab;
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 5px;
      @media (max-width: 750px) {
        font-size: 13px;
      }
    }
    .caption {
      font-size: 30px;
      font-weight: 600;
      text-transform: capitalize;
      @media (max-width: 750px) {
        font-size: 18px;
      }
    }
    .visual {
      margin-top: 10px;
      img {
        height: 30px;
        @media (max-width: 750px) {
          height: 25px;
        }
      }
    }
  }
}
