@use "sass:color";

.MarbleverseIntro {
  $breakpoint-lg: 1100px;
  $breakpoint-sm: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;

  .jumbotron {
    display: flex;
    align-items: center;
    z-index: 1;
    background: none;
    border: none;
    border-radius: 0;
    margin: 0;
    width: 100vw;
    max-width: 1400px;
    text-align: center;
    @media (orientation: portrait), (max-width: $breakpoint-lg) {
      flex-direction: column;
      padding: 1rem 2rem;
    }

    .teaser {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      padding: 0 30px;
      @media (max-width: $breakpoint-lg) {
        padding: 0 0 80px;
        width: 100%;
      }
      @media (max-width: $breakpoint-lg) and (max-height: 730px) {
        padding: 0 0 70px;
      }
      @media (max-width: $breakpoint-lg) and (max-height: 600px) {
        padding: 0 0 50px;
      }

      .brand {
        display: flex;
        font-size: 30px;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        @media (max-width: $breakpoint-lg) {
          font-size: 24px;
        }
        @media (max-width: $breakpoint-sm) {
          font-size: 22px;
        }

        img {
          width: 42px;
          margin-right: 20px;
          @media (max-width: $breakpoint-sm) {
            width: 36px;
          }
        }
      }

      .claim {
        font-size: 37px;
        max-width: 480px;

        [lang='de'] & {
          max-width: 530px;
        }

        @media (max-width: $breakpoint-lg) {
          font-size: 28px;
          max-width: 380px;
          [lang='de'] & {
            max-width: 410px;
          }
        }
        @media (max-width: $breakpoint-sm) {
          font-size: 26px;
          [lang='de'] & {
            font-size: 23px;
            max-width: 330px;
          }
        }
      }
    }

    .video {
      width: 50%;
      display: flex;
      justify-content: center;
      @media (max-width: $breakpoint-lg) {
        width: 100%;
        max-width: 500px;
      }

      .VideoBox {
        margin: 0;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: none;
        width: 80%;
        @media (max-width: $breakpoint-lg) {
          width: 100%;
          max-width: 400px;
        }

        .SliderContainer {
          height: 300px;
          margin-left: auto;
          margin-right: auto;
          @media (max-width: $breakpoint-lg) {
            width: 100%;
            height: 225px;
          }

          iframe {
            border-radius: 15px;
          }
        }
      }
    }
  }
  .ScrollHint {
    position: absolute;
    bottom: 40px;
    @media (max-height: 900px) {
      bottom: 15px;
    }
  }
  .MarbleversePartners {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
