.CreatorsBenefit {
  display: flex;
  justify-content: space-between;
  line-height: 2;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  text-align: right;
  &:nth-child(2n) {
    background-position: top left;
    flex-direction: row-reverse;
    text-align: left;
  }
  .visual {
    img {
      width: 33vw;
      max-width: 370px;
      @media (max-width: 1000px) {
        width: 40vw;
      }
    }
  }
  .text {
    width: 33vw;
    max-width: 370px;
    padding-top: 7vw;
    @media (min-width: 1300px) {
      padding-top: 85px;
    }
    @media (max-width: 1000px) {
      width: 50vw;
    }
    @media (max-width: 750px) {
      font-size: 13px;
    }
    .caption {
      font-size: 30px;
      font-weight: 600;
      @media (max-width: 750px) {
        font-size: 24px;
      }
    }
  }
  .cta {
    font-weight: 800;
    margin-top: 5px;
    color: #ff00ab;
    text-transform: capitalize;
  }
}
