.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  .backdrop {
    position: absolute;
    background: transparentize(black, 0.3);
    z-index: 1040;
    width: 100%;
    height: 100%;
  }
  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .modal-content {
    width: 600px;
    max-width: 95vw;
    max-height: 95vh;
    padding: 30px;
    z-index: 1050;
    background-color: white;
    background-clip: padding-box;
    border: 2px solid rgba(1, 0, 30, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  .modal-header {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    color: #ff00ab;
  }
}
