.CreatorsFeature {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  @media (max-width: 750px) {
    justify-content: space-between;
    margin-top: 75px;
  }
  &:nth-child(2n) {
    .visual {
      margin-right: 5vw;
    }
  }
  &:nth-child(2n + 1) {
    flex-direction: row-reverse;
    .visual {
      margin-left: 5vw;
    }
  }
  .visual {
    img {
      width: 33vw;
      max-width: 370px;
      @media (max-width: 1000px) {
        width: 37vw;
      }
    }
  }
  .text {
    width: 33vw;
    max-width: 370px;
    @media (max-width: 1000px) {
      width: 45vw;
    }
    @media (max-width: 750px) {
      font-size: 13px;
    }
    .caption {
      font-size: 24px;
      font-weight: 600;
      @media (max-width: 750px) {
        font-size: 18px;
      }
    }
  }
}
