.VideoBox {
  .Slider {
    height: 100%;
  }
  .slider-list,
  .slider-slide {
    height: 100% !important;
    width: 100% !important;
  }
  .Slide,
  .Slide iframe {
    width: 100%;
    height: 100%;
  }
}
