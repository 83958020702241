$phone: 700px;

.NftActivity {
  background: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: translateY(100vh);
  .visual {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh 10vw 10vh 25vw;
    @media (max-width: $phone) {
      padding: 0;
    }
    .background {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $phone) {
        margin-left: 0;
        padding-top: 20vh;
      }
    }
    .box {
      position: absolute;
      left: 7vw;
      width: 30vw;
      max-width: 450px;
      height: 35vh;
      background: black;
      color: white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      @media (max-width: $phone) {
        left: 0;
        top: 3vh;
        width: 70vw;
        max-width: 250px;
        height: 22vh;
        padding: 20px;
      }
      .caption {
        font-size: 60px;
        font-weight: 600;
        @media (max-width: $phone) {
          font-size: 40px;
        }
        @media (max-width: $phone) and (max-height: 650px) {
          font-size: 32px;
        }
      }
      .description {
        font-size: 32px;
        @media (max-width: $phone) {
          font-size: 22px;
        }
        @media (max-width: $phone) and (max-height: 650px) {
          font-size: 18px;
        }
      }
    }
    .Phone {
      height: 60%;
      margin-left: 15vw;
      @media (max-width: $phone) {
        height: 60%;
        margin-left: 0;
      }
    }
  }
  &.reverse {
    justify-content: flex-start;
    .visual {
      padding: 10vh 25vw 10vh 10vw;
      @media (max-width: $phone) {
        padding: 0;
      }
    }
    .box {
      left: initial;
      right: 7vw;
      @media (max-width: $phone) {
        left: initial;
        right: 0;
        top: 3vh;
      }
    }
    .Phone {
      margin-left: 0;
      margin-right: 15vw;
      @media (max-width: $phone) {
        margin-right: 0;
      }
    }
  }
  &.trade {
    .Phone {
      .tap {
        left: 80%;
        top: 60%;
      }
    }
  }
}
