.CreatorsNavbar {
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  .logo {
    height: 34px;
  }
  .nav-tabs {
    display: flex;
    cursor: pointer;
    div {
      padding: 5px 20px;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
  .app-access {
    display: flex;
    white-space: nowrap;
    div {
      margin: 0 5px;
      padding: 5px 10px;
      cursor: pointer;
    }
    .sign-up {
      background: white;
      color: darken(pink, 70%);
      border-radius: 8px;
    }
  }
}
