.CreatorsStats {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .stats {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 130px;
    .number {
      font-size: 24px;
      font-weight: 600;
    }
    .caption {
      color: $gray-600;
    }
  }
}
