.MarbleverseBenefit {
  .main-visual-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    padding: 50px;
    display: flex;
    .main-visual {
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .bubble-wrapper {
        position: relative;
        img {
          width: 100%;
          border-radius: 50%;
        }
        .description-bubble {
          position: absolute;
          right: -10vw;
          top: 50%;
          width: 25vw;
          height: 25vw;
          transform: translateY(-50%);
          background: white;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-weight: 600;
          color: $gray-600;
          font-size: 2vw;
          padding: 3vw;
          .caption {
            font-size: 3vw;
          }
          .MarbleverseDownloadButton {
            border-color: $gray-600;
            font-size: 1.5vw;
            margin-top: 20px;
            white-space: nowrap;
          }
        }
      }
    }
    &.reverse {
      flex-direction: row-reverse;
      .main-visual {
        .bubble-wrapper {
          .description-bubble {
            right: 0;
            left: -10vw;
          }
        }
      }
    }
  }
}
