.MarbleversePartners {
  padding: 40px;
  display: flex;
  justify-content: center;
  color: white;
  .caption {
    margin-bottom: 20px;
    display: none;
  }
  .logos {
    width: 95vw;
    max-width: 1100px;
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 30px;
    justify-items: center;
    align-items: center;
    @media (orientation: portrait) {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 15px;
      row-gap: 25px;
    }
    opacity: 1;
    .logo {
      max-width: 100%;
      max-height: 44px;
      &.techstars {
        max-height: 48px;
      }
      &.sxsw {
        max-height: 88px;
      }
      @media (max-width: 500px) {
        max-height: 30px;
        &.techstars {
          max-height: 40px;
        }
        &.sxsw {
          max-height: 60px;
        }
      }
    }
  }
}
