.MarbleverseCallToAction {
  .cta {
    color: white;
    text-align: center;
    font-size: 40px;
    margin-bottom: 50px;
    @media (orientation: portrait) {
      font-size: 24px;
    }
  }
  .MarbleverseDownloadButton {
    text-align: center;
    .AppstoreBadges {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 44px;
      }
    }
  }
  .MarbleversePartners {
    position: absolute;
    bottom: 48px;
    left: 0;
    width: 100%;
  }
}
