.form-row {
  display: flex;
  flex-direction: column;
  label {
    font-weight: 600;
    margin-bottom: 5px;
    span {
      color: #ff00ab;
    }
  }
}

input,
textarea {
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid $gray-400;
  font-size: 17px;
}

input {
}

button {
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  line-height: 1;
  padding: 12px 30px 10px;
  border: 1px solid #007cb7;
  color: #007cb7;
  background: white;
  &.primary {
    background: #007cb7;
    color: white;
  }
  &:not(:last-of-type) {
    margin-right: 15px;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
