.MarbleverseDownloadButton {
  //$breakpoint: 1100px;
  //border: 2px solid white;
  //border-radius: 40px;
  //padding: 6px 30px 6px;
  //font-size: 24px;
  //font-weight: 400;
  margin-top: 30px;
  //cursor: pointer;
  //@media (max-width: $breakpoint) {
  //  font-size: 18px;
  //  padding: 4px 20px 2px;
  //}

}
