.CreatorsContactForm {
  .confirmation {
    margin-bottom: 30px;
    text-align: center;
  }
  .actions {
    display: flex;
    justify-content: center;
    button {
      min-width: 100px;
    }
  }
}
