.NftIntro {
  .hero-banner {
    height: 90vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;
    display: flex;
    justify-content: center;
  }
  .brand {
    position: absolute;
    left: 30px;
    top: 30px;
    display: flex;
    align-items: center;
    .logo {
      width: 36px;
      @media (max-width: $phone) {
        width: 30px;
      }
    }
    .name {
      color: black;
      font-weight: 600;
      margin-left: 15px;
      font-size: 20px;
      @media (max-width: $phone) {
        font-size: 18px;
      }
    }
  }
  .intro {
    margin-top: 10vh;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    margin-left: 0;
    max-width: 275px;
    color: black;
    text-shadow: white 2px 2px;
    @media (max-width: $phone) {
      margin-top: 12vh;
    }
    .claim {
      display: flex;
      text-align: center;
    }
    .signup-button {
      font-size: 18px;
      margin-top: 20px;
      border: 3px solid black;
      box-shadow: $gray-200 1px 1px 3px 3px;
      border-radius: 30px;
      padding: 7px 30px;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  .ScrollHint {
    position: absolute;
    bottom: 20px;
  }
  .Phone {
    bottom: 0;
    height: 77vh;
    transform: translateY(100vh);
    @media (max-width: $phone) {
      bottom: 3vh;
      height: 68vh;
    }
    .marbles {
      top: -17vh;
      @media (max-width: $phone) {
        top: -15.5vh;
      }
    }
  }
}
