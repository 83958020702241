.MarbleverseInfoMarble.page {
  .background {
    background-position: bottom right;
  }
  .content {
    @media (orientation: portrait) {
      //padding: 30px;
    }
    .caption {
      font-size: 48px;
      margin-bottom: 50px;
      text-align: center;
      @media (orientation: portrait) {
        font-size: 36px;
      }
    }
  }
  .info-marble {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    width: 100%;
    .description {
      position: absolute;
      left: 0;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (orientation: portrait) {
        width: 100%;
        bottom: 10%;
        align-items: flex-end;
        font-size: 24px;
      }
      color: white;
      font-size: 36px;
    }
    .marble {
      position: absolute;
      left: 50%;
      width: 50%;
      height: 100%;
      @media (orientation: portrait) {
        left: 0;
        width: 100%;
      }
      .Phone {
        height: 130%;
      }
    }
  }
}
