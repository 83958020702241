.CreatorsTiers {
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-margin-top: 100px;
  @media (max-width: 750px) {
    flex-direction: column;
    justify-content: start;
    .CreatorsTier {
      margin-top: 30px;
    }
  }
  .background {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
