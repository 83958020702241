.CreatorsButton {
  $breakpoint-sm: 420px;
  background: transparentize(white, 0.1);
  color: black;
  padding: 7px 15px 5px;
  line-height: 1;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 40px;
  @media (max-width: $breakpoint-sm) {
    right: 25px;
  }
}
