.Phone {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  .wrapper {
    height: 100%;
    aspect-ratio: 420 / 806;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .frame {
    position: relative;
    height: 100%;
  }
  .mask {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 92%;
    aspect-ratio: 390 / 842;
    border-radius: 30px;
    overflow: hidden;
  }
  .ux {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 120%;
  }
  .app-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .marbles,
  .tap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .tap {
    video {
      opacity: 0.7;
      width: 850px;
      @media (max-width: $phone) {
        width: 750px;
      }
    }
  }
}
