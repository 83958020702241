.CreatorsTier {
  display: flex;
  flex-direction: column;
  padding: 60px;
  border-radius: 8px;
  text-align: center;
  background: white;
  min-width: 350px;
  align-items: center;
  border: 1px solid $gray-200;
  &:nth-child(2n) {
    @media (min-width: 751px) {
      box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.05);
      border: none;
    }
    z-index: 1;
    button {
      background: #007cb7;
      color: white;
      border: none;
    }
  }
  &:nth-child(2n + 1) {
    button {
      border: 1px solid #007cb7;
      color: #007cb7;
      background: white;
    }
  }
  .name {
    color: #ff00ab;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
  }
  .target-group {
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  .price {
    .currency,
    .duration {
      position: relative;
      font-style: italic;
      color: $gray-600;
      font-size: 20px;
    }
    .currency {
      top: -26px;
      padding-right: 2px;
    }
    .duration {
      top: -3px;
      padding-left: 2px;
    }
    .amount {
      font-size: 48px;
    }
    .custom {
      font-size: 32px;
      margin-bottom: 10px;
    }
  }
  .cta {
    margin-top: 15px;
    button {
      border-radius: 4px;
      cursor: pointer;
      font-size: 15px;
      line-height: 1;
      padding: 12px 30px 10px;
    }
  }
  .features {
    margin-top: 30px;
    text-align: left;
    line-height: 1.8;
    font-size: 15px;
    .group {
      font-weight: 600;
      margin-top: 10px;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .comment {
      margin-top: 10px;
      color: #aaa;
    }
  }
}
