.AppstoreBadges {
  img {
    height: 48px;
    cursor: pointer;
    margin: 0 10px;
    &:first-of-type {
      padding-left: 0;
    }
    &.google {
      background: white;
      border-radius: 7px;
    }
  }
}
