$phone: 700px;

.NftTestimonial {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $gray-700;
  text-align: center;
  padding: 30px;
  font-size: 20px;
  @media (max-width: $phone) {
    font-size: 18px;
  }
  .statement {
    font-size: 34px;
    margin-bottom: 10px;
    @media (max-width: $phone) {
      font-size: 26px;
    }
  }
}
