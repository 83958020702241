/**
 * @license
 * MyFonts Webfont Build ID 3805226, 2019-09-07T05:14:51-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website.moments.moments owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Folito-Light by Indian Tywpe Foundry
 * URL: https://www.myfonts.com/fonts/indian-type-foundry/folito/light/
 *
 * Webfont: Folito-Regular by Indian Type Foundry
 * URL: https://www.myfonts.com/fonts/indian-type-foundry/folito/regular/
 *
 * Webfont: Folito-Medium by Indian Type Foundry
 * URL: https://www.myfonts.com/fonts/indian-type-foundry/folito/medium/
 *
 * Webfont: Folito-Bold by Indian Type Foundry
 * URL: https://www.myfonts.com/fonts/indian-type-foundry/folito/bold/
 *
 * Webfont: Folito-Black by Indian Type Foundry
 * URL: https://www.myfonts.com/fonts/indian-type-foundry/folito/black/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3805226
 * Licensed pageviews: 1,100,000
 * Webfonts copyright: Copyright 2017 Fontstore Pte Ltd. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
// @import url('#{$cdn}//hello.myfonts.net/count/3a102a');

$cdn: 'https://marblever.se';

@font-face {
  // Folito Light
  font-family: 'Folito';
  src: url('#{$cdn}/fonts/folito/3A102A_0_0.eot');
  src: url('#{$cdn}/fonts/folito/3A102A_0_0.eot?#iefix') format('embedded-opentype'),
  url('#{$cdn}/fonts/folito/3A102A_0_0.woff2') format('woff2'),
  url('#{$cdn}/fonts/folito/3A102A_0_0.woff') format('woff'),
  url('#{$cdn}/fonts/folito/3A102A_0_0.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
}

@font-face {
  // Folito Regular
  font-family: 'Folito';
  src: url('#{$cdn}/fonts/folito/3A102A_1_0.eot');
  src: url('#{$cdn}/fonts/folito/3A102A_1_0.eot?#iefix') format('embedded-opentype'),
  url('#{$cdn}/fonts/folito/3A102A_1_0.woff2') format('woff2'),
  url('#{$cdn}/fonts/folito/3A102A_1_0.woff') format('woff'),
  url('#{$cdn}/fonts/folito/3A102A_1_0.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  // Folito Medium
  font-family: 'Folito';
  src: url('#{$cdn}/fonts/folito/3A102A_2_0.eot');
  src: url('#{$cdn}/fonts/folito/3A102A_2_0.eot?#iefix') format('embedded-opentype'),
  url('#{$cdn}/fonts/folito/3A102A_2_0.woff2') format('woff2'),
  url('#{$cdn}/fonts/folito/3A102A_2_0.woff') format('woff'),
  url('#{$cdn}/fonts/folito/3A102A_2_0.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  // Folito Bold
  font-family: 'Folito';
  src: url('#{$cdn}/fonts/folito/3A102A_3_0.eot');
  src: url('#{$cdn}/fonts/folito/3A102A_3_0.eot?#iefix') format('embedded-opentype'),
  url('#{$cdn}/fonts/folito/3A102A_3_0.woff2') format('woff2'),
  url('#{$cdn}/fonts/folito/3A102A_3_0.woff') format('woff'),
  url('#{$cdn}/fonts/folito/3A102A_3_0.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  // Folito Black
  font-family: 'Folito';
  src: url('#{$cdn}/fonts/folito/3A102A_4_0.eot');
  src: url('#{$cdn}/fonts/folito/3A102A_4_0.eot?#iefix') format('embedded-opentype'),
  url('#{$cdn}/fonts/folito/3A102A_4_0.woff2') format('woff2'),
  url('#{$cdn}/fonts/folito/3A102A_4_0.woff') format('woff'),
  url('#{$cdn}/fonts/folito/3A102A_4_0.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
}
