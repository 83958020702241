.CreatorsZero {
  display: flex;
  flex-direction: column;
  width: 100%;
  .claim {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #ff00ab;
    margin-bottom: 30px;
  }
  .zeros {
    display: flex;
    justify-content: space-around;
    margin-top: 5vh;
    margin-bottom: 2vh;
    img {
      height: 150px;
      @media (max-width: 800px) {
        height: auto;
        width: 20vw;
      }
    }
  }
}
