.CreatorsFooter {
  $color: $gray-700;
  position: relative;
  display: flex;
  width: 100%;
  font-size: 15px;
  padding: 12px 10px 10px;
  z-index: 3;
  text-align: center;
  font-weight: 400;
  height: 22vw;
  justify-content: center;
  align-items: flex-end;
  color: $color;
  overflow: hidden;
  .background {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: -1;
  }
  @media (max-width: 750px) {
    height: 30vw;
    font-size: 14px;
  }
  &,
  & a,
  a:visited,
  a:hover {
    color: $color;
  }
  span,
  a {
    padding: 0 10px;
  }
}
