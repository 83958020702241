.LoadingSpinner {
  width: 15px;
  height: 15px;
  color: white;
  animation: spinner 1s infinite;
  &.hidden {
    display: none;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
