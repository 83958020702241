@import '../../variables/colors';

.Marbleverse {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  //.fake-scroll {
  //  height: calc(600vh + 300vw + 5500px);
  //}

  > div:not(.fake-scroll):not(.MarbleverseBubbles) {
    position: fixed;
    width: 100vw;
    &:not(.MarbleverseIntro) {
      transform: translate3d(0, 100vh, 0);
    }
  }

  .page {
    display: none;
    &.visible {
      display: flex;
    }
    align-items: center;
    justify-content: center;
    //width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    &.MarbleverseInfoMarble {
      overflow: initial;
    }

    .background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: -1;
    }

    .content {
      width: 100%;
      height: 100vh;
      .ios & {
        padding-top: 3vh;
      }
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-height: 600px) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      @media (min-width: 600px) {
        padding-left: 60px;
        padding-right: 60px;
      }

      .caption {
        font-size: 36px;
        color: white;
        font-weight: 400;
        margin-bottom: 15px;

        &.dark {
          color: $gray-600;
        }
      }

      .visual {
        flex-grow: 2;
        height: 100%;
        width: 100%;
        .Phone {
          height: 100%;
        }
      }
    }
  }
}

.ControlledVideo {
  opacity: 0.99;
  .marble-pink & {
    opacity: 0.7;
    .macos.safari & {
      opacity: 0.6;
    }
  }
}
