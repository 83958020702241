.MarbleverseFakeMarble {
  z-index: -1;
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  .fake-marble {
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    background: transparent;
    top: 50%;
    filter: blur(1px);
    transform: translateY(-60%);
  }
}
