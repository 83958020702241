.MarbleverseFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 18px;
  background: black;
  padding: 12px 10px 10px;
  z-index: 3;
  text-align: center;
  font-weight: 400;
  @media (max-width: 800px) {
    font-size: 14px;
  }
  &,
  & a,
  a:visited,
  a:hover {
    //color: #511074;
    color: white;
  }
  span,
  a {
    padding: 0 10px;
  }
}
