.MarbleverseFeature {
  .background {
    display: flex;
    justify-content: center;
  }
  .feature-content {
    display: flex;
    flex-direction: row;
    padding: 100px;
    @media (max-width: 1000px), (max-height: 600px) {
      padding: 30px;
    }
    height: 100%;
    justify-content: center;
    @media (orientation: portrait) {
      flex-direction: column;
      text-align: center;
    }
    width: 100%;
    max-width: 1600px;
    .infobox,
    .visual {
      width: 50%;
      @media (orientation: portrait) {
        width: 100%;
      }
    }
    .infobox {
      color: white;
      font-size: 40px;
      padding-left: 50px;
      padding-right: 50px;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      @media (orientation: portrait) {
        padding-left: 0;
        padding-right: 0;
        align-items: center;
        justify-content: flex-end;
        font-size: 30px;
        height: 35%;
        padding-bottom: 50px;
      }
      display: flex;
      font-weight: 200;
      .caption {
        font-size: 60px;
        white-space: nowrap;
        line-height: 1;
        display: flex;
        flex-direction: column;
        @media (orientation: portrait) {
          font-size: 44px;
        }
        svg {
          margin-bottom: 20px;
          transform-origin: top left;
        }
      }
      .description {
        line-height: 1.3;
      }
    }
    .visual {
      position: relative;
      @media (orientation: portrait) {
        height: 65%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .video {
      width: 100%;
      @media (orientation: portrait) {
        padding-top: 50px;
      }
      @media (orientation: landscape) {
        height: 100%;
      }
    }
    .Phone {
      height: 100%;
    }
  }
  &.everyone {
    .visual-everyone {
      display: flex;
      justify-content: left;
      align-items: center;
      position: relative;
      margin-top: -100px;
      margin-right: -150px;
      margin-bottom: -100px;
      @media (orientation: portrait) {
        margin-right: -30px;
        margin-left: -30px;
        margin-bottom: -30px;
      }
      height: calc(100% + 200px);
      > .marbles {
        width: 50%;
        height: 100%;
      }
      .user {
        width: calc(100% + 150px);
        height: auto;
        position: relative;
        left: -50%;
      }
    }
  }
}
